<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-16 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center justify-between w-full  border-gray-200 p-6 mt-5">
        <div class="flex w-full">
          <button :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()">
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_user") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_user") }}
          </div>
        </div>



        <div v-if="formData._id && formData.role.type == 'Courier'"
          class=" pt-3 pb-4 flex justify-end items-center w-full">
          <button
            class="rounded focus:outline-none outline-none text-gray-100 px-3 w-fit py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
            @click="$vm2.open('GenerateModal')">
            {{ $t("Generate Credentials") }}
          </button>
        </div>


      </div>



      <div class="relative">
        <loading :active.sync="loading" :is-full-page="fullPage"></loading>
        <div class="bg-white rounded-lg p-6">
          <div class="grid lg:grid-cols-2 gap-6">
            <div
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="fullName" class="bg-white text-gray-700 px-1">{{ $t("full_name") }} *</label>
                </p>
              </div>
              <p>
                <input id="fullName" :disabled="formData._id" autocomplete="false" tabindex="0" type="text"
                  v-model="formData.fullName" class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>
            <div v-if="formData._id"
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="phone" class="bg-white text-gray-700 px-1">{{ $t("phone") }} *</label>
                </p>
              </div>
              <p>
                <input id="phone" autocomplete="false" tabindex="0" type="text" v-model="formData.phone"
                  class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>
            <div
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="username" class="bg-white text-gray-700 px-1">{{ $t("username") }} *</label>
                </p>
              </div>
              <p>
                <input id="username" autocomplete="false" tabindex="0" type="text" v-model="formData.username"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                  @keydown.space="(event) => event.preventDefault()" />
              </p>
            </div>

            <div
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="email" class="bg-white text-gray-700 px-1">{{ $t("email") }} *</label>
                </p>
              </div>
              <p>
                <input autocomplete="false" tabindex="0" type="text" v-model="formData.email"
                  class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full" />
              </p>
            </div>

            <div v-if="formData._id"
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="company" class="bg-white text-gray-700 px-1">{{ $t("store") }} *</label>
                </p>
              </div>
              <p>
                <input autocomplete="false" tabindex="0" type="text" v-model="formData.company"
                  class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>



            <div
              class="border h-10 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1">{{ $t("role") }} *</label>
                </p>
              </div>
              <p>
                <v-select label="name" @input="onChangeRole(formData.role)" @search="searchRoles($event,'hh')"
                  v-model="formData.role" :options="roles"></v-select>
                <!-- <select
                  autocomplete="type"
                  v-model="formData.role"
                  @change="onChangeRole(formData.role)"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">Role</option>
                  <option v-for="role in roles" :key="role._id" :value="role">
                    {{ role.name }}
                  </option>
                </select> -->
              </p>
            </div>

            <div v-if="livreur && selectedRole == livreur"
              class="border hover:border-green-500 mt-4 px-4 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1">{{ $t("select_zones") }}
                    <span :class="$colors.required">*</span></label>
                </p>
              </div>

              <div class="">
                <div class="w-full mt-2">
                  <table class="table w-full text-center">
                    <thead class="bg-gray-100 text-black">
                      <tr>
                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider">
                          {{ $t("zones") }}
                        </th>

                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider">
                        </th>
                        <th
                          class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider">
                          {{ $t("add") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in zones" :key="index" :class="index % 2 == 0 ? 'bg-gray-100' : ''">
                        <td class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm">
                          <p class="text-gray-700 capitalize">
                            {{ item.name }}
                          </p>
                        </td>

                        <td class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"></td>
                        <td class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm">
                          <p class="text-gray-600 ml-3 text-semibold capitalize">
                            <label class="inline-flex text-sm items-center capitalize">
                              <input @change="addZones(item)" @click="getRowDetail(item._id)" v-model="item.check"
                                type="checkbox" :id="item._id"
                                class="form-checkbox rowCheckbox border-gray-500 h-4 w-4 text-green-500" />
                            </label>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="formData._id && formData.type == 'Seller'"
              class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="rib" class="bg-white text-gray-700 px-1">{{ $t("rib") }} *</label>
                </p>
              </div>
              <p>
                <input autocomplete="false" tabindex="0" type="text" v-model="formData.rib"
                  class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>

            <!-- coutries -->
            <div v-if="
              $f.getAccess(currentUser.role.permissions, 'users', 'create')
            "
              class="border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="Countries" class="bg-white text-gray-700 px-1">
                    {{ $t("countries") }}</label>
                </p>
              </div>
              <p>
                <v-select label="countryName" @input="setSelectedCountries" v-model="formData.countries"
                  :options="warehouses" multiple>
                  <template slot="option" slot-scope="option">
                    <img class="flag-img" :src="$f.getCountryCode(option.country)" />
                    {{ option.countryName }}
                  </template>
                </v-select>
              </p>
            </div>

            <!-- /coutries -->
            <div v-if="!formData._id" class="flex items-center justify-between">
              <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
              <span @click="CreateNewRole"
                class="text-xs text-green-500 font-bold cursor-pointer uppercase dark:text-green-400 hover:underline">{{
                  $t("create_new_role") }}</span>
              <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
            </div>


          </div>
          <!--fees seller -->
          <div v-if="formData._id && formData.type == 'Seller'">
            <div class="flex mt-10 mb-5 items-center text-center">
              <hr class="border-gray-300 border-1 w-full rounded-md" />
              <label @click="Showfees"
                class="block font-medium font-medium text-base cursor-pointer text-green-500 w-full"><span
                  class="font-medium text-base text-green-800 dark:text-green-200">{{ $t("fees_seller") }}</span>
                <span class="block"><i class="material-icons" style="transition: 0.3s"
                    :style="showMoreInfo ? 'transform: rotate(180deg)' : ''">keyboard_arrow_down</i></span></label>
              <hr class="border-gray-300 border-1 w-full rounded-md" />
            </div>

            <div v-if="showMoreInfo" class="flex flex-wrap">
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="grid-password">{{ $t("confirmation_fees") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.confirmation" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="fulfillement_fees">{{ $t("fulfillement_fees") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.fulfillement_fees" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="grid-password">{{ $t("shipping_fees") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.shipping" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="grid-password">{{ $t("return_fees") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.refund" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="grid-password">{{ $t("cancelation_fees") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.cancelation" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
              <div class="w-full lg:w-1/5 px-4 mb-4">
                <div
                  class="border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label class="bg-white text-gray-700 px-1" for="grid-password">{{ $t("quality_control") }}
                        <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                    </p>
                  </div>
                  <p>
                    <input type="number" v-model="fees.quality_control" min="0"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
              </div>
            </div>

            <!--services -->
            <div class="flex mt-10 mb-5 items-center text-center">
              <hr class="border-gray-300 border-1 w-full rounded-md" />
              <label class="block font-medium text-sm cursor-pointer text-green-500 w-full"><span
                  class="font-medium text-base text-green-800 dark:text-green-200">{{ $t("services") }}</span>
              </label>
              <hr class="border-gray-300 border-1 w-full rounded-md" />
            </div>

            <div class="flex flex-col lg:flex-row">
              <div id="confirmation" @click="activate('confirmation')" :class="{ active: settings.confirmation }"
                class="intro-y flex-1 box-1 py-16 cursor-pointer zoom-in">
                <div class="check">
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg></span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <div class="text-xl font-medium text-center mt-10">
                  Confirmation
                </div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
              <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
              </div>
              <div id="fulfillement" @click="activate('fulfillement')" :class="{ active: settings.fulfillement }"
                class="intro-y flex-1 box-1 py-16 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                <div class="check">
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg></span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <div class="text-xl font-medium text-center mt-10">
                  Warehouse
                </div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
              </div>
              <div id="shipping" @click="activate('shipping')" :class="{ active: settings.shipping }"
                class="intro-y flex-1 box-1 py-16 lg:ml-5 cursor-pointer zoom-in">
                <div class="check">
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg></span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <div class="text-xl font-medium text-center mt-10">
                  Shipping
                </div>
                <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
              </div>
            </div>
          </div>
          <!--/ fees seller -->
          <div class=" flex justify-center">
            <button
              class="rounded mt-24 focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
              @click="save">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <vue-modal-2 name="GenerateModal" :headerOptions="{ title: $t('Generate Credentials') }" :noFooter="true"
      modalSize="lg" @on-close="CloseModal('GenerateModal')">
      <div class="relative flex flex-col w-full h-auto">
        <loading :active.sync="generateLoading" :is-full-page="fullPage"></loading>

        <!-- Modal Content-->
        <div class="bg-white rounded px-8 flex flex-col">
          <div class="mb-3">


            <div v-if="formData && formData.fullName && formData.fullName.toString().toLowerCase() !== 'camex'">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="apiKey">{{ $t("Api Key") }}</label>
              <div
                class="focus:outline-none h-12 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3">
                <p> {{ formData.api.key ? formData.api.key : 'api key ....' }} </p>
              </div>
            </div>


            <label class="block text-grey-darker text-sm font-bold mb-2" for="apiSecret">{{ $t("Api Secret") }}</label>
            <div
              class="focus:outline-none shadow h-12 appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3">
              <p> {{ formData.api.secret ? formData.api.secret : 'api secret ....' }} </p>
            </div>

            <div v-if="!formData.api.secret && !formData.api.key" class="flex space-x-2">
              <button @click="GenerateCredentials(formData._id)"
                class="rounded focus:outline-none outline-none text-gray-100 px-3 w-full my-4 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">{{
                  $t("Generate") }}</button>
            </div>
            <div v-else class="flex space-x-2">
              <button @click="CopyCredentials(formData.api.key, formData.api.secret, formData.fullName)"
                class="rounded focus:outline-none outline-none text-gray-100 px-3 w-full my-4 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">{{
                  $t("Copy Credentials") }}</button>
            </div>
          </div>
        </div>
        <!-- End of Modal Content-->


      </div>
    </vue-modal-2>
  </div>
</template>

<script>
import configStatus from "../callcenter/configStatus";
import _ from 'lodash';

export default {
  data() {
    return {
      formData: {
        category: null,
        role: null,
        zones: [],
        countries: [],
        fees: {},
        settings: [],
        type: "",
        api: {
          key: null,
          secret: null,
        }
      },
      generateLoading: false,
      roles: [],
      zones: [],
      selectContries: [],
      currentZone: [],
      allZones: [],
      types: [
        "Seller",
        "TeleConsultant",
        "NRP",
        "StockManager",
        "Courier",
        "Accountant",
        "Return",
        "Admin",
        "CancelledOrder",
        "ToRemind",
      ],
      categories: [],
      warehouses: [],
      options: this.$countries,
      livreur: "",
      selectedRole: "",
      roleformdata: "",
      tempZones: [],
      checkbox: true,
      showMoreInfo: false,
      loading: false,
      fullPage: false,
      currency: "",
      idWharhouse: null,
      currentUser: {
        role: {},
      },
      fees: {
        confirmation: -1,
        fulfillement_fees: -1,
        shipping: -1,
        refund: -1,
        change: -1,
        quality_control: -1,
      },
      settings: {
        open_colis: false,
        confirmation: false,
        fulfillement: false,
        shipping: false,
      },
    };
  },
  async mounted() {
    await this.getUser();
    await this.getwharhouseId();
    await this.getCategories();
    await this.getRoles();
    await this.getZones();
    await this.dataToEdit();
    await this.getRoleByType();
    await this.getWarehouses();
    this.getDtaStorage();
    if (this.formData._id) {
      this.formData.role = this.roleformdata;
    }
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      await this.getwharhouseId();
      await this.dataToEdit();
      await this.getZones();
    },
  },
  methods: {
    CopyCredentials(key, secret, name) {
      let connectionString = null;
      let payload = null; 

      if (name.toString().toLowerCase() === 'camex') { 
        connectionString = `https://api-cwd.speedliv.com/api/shippings/apiupdate`;
        payload = {
          "secretKey": secret,
          "State": 0,
          "Id": 9569456
        };
      } else {
        connectionString = `https://api-cwd.speedliv.com/api/shippings/apiupdate?key=${key}&secret=${secret}&source=shipping`;
        payload = {
          "tracking_id": "764765764455",
          "status": "En cours"
        };
      }

      const input = document.createElement('textarea');
      input.value = connectionString + '\n' + 'Payload: ' + JSON.stringify(payload); 
      document.body.appendChild(input);
      input.select();

      document.execCommand('copy');
      document.body.removeChild(input);

      alert('Connection String Copied!' , 'success'); 
    }
,
    RouterBack: function () {
      this.$router.back();
    },
    activate: function (el) {
      this.settings[el] = !this.settings[el];
    },
    async getUser() {
      const res = await this.$server.me("users");
      //
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getCurrency() {
      await this.$store.dispatch("wharhouse/getCurrency").then((res) => {
        this.currency = res;
      });
    },
    async changeData(data, warhouse, element) {
      for (var i in data) {
        if (data[i].warehouse == warhouse.toString()) {
          data[i] = element;
          break; //Stop this loop, we found it!
        }
      }
    },
    async GenerateCredentials(id) {
      this.generateLoading = true
      const res = await this.$server.GenerateCredentials("users", { _id: id });
      this.generateLoading = false

      if (res) {
        this.formData.api.secret = res.api.secret
        this.formData.api.key = res.api.key
      }

    },
    async CloseModal(id) {
      this.$vm2.close(id);

    },
    async open(name) {
      this.$vm2.open(name);
    },
    setSelectedCountries(countries) {
      if (countries) {
        this.formData.countries.forEach(
          function (part, index, theArray) {
            if (typeof part === "object" && part !== null)
              theArray[index] = part.countryName;
          }.bind(this)
        );
        let uniqueChars = this.formData.countries.filter((c, index) => {
          return this.formData.countries.indexOf(c) === index;
        });
        this.formData.countries = uniqueChars;
      }
    },
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        this.warehouses = res.content.results;
        // for(let i in data){
        //   this.warehouses.push({name:this.$f.getCountryNameByCode(this.options,data[i].country),code:data[i].country})
        // }
      } else this.warehouses = [];
    },
    getDtaStorage() {
      //
      if (
        localStorage.getItem("Role") != null &&
        localStorage.getItem("User_email") != null
      ) {
        let Role = JSON.parse(localStorage.getItem("Role"));
        this.formData.email = localStorage.getItem("User_email");
        this.selectedRole = Role;
        this.formData.role = Role;
        this.formData.type = Role.type;
        if (localStorage.getItem("User_username") != null)
          this.formData.username = localStorage.getItem("User_username");
        if (localStorage.getItem("User_fullName") != null)
          this.formData.fullName = localStorage.getItem("User_fullName");
      }
    },
    DeleteDtaStorage() {
      localStorage.removeItem("Role");
      localStorage.removeItem("User_email");
      localStorage.removeItem("User_username");
      localStorage.removeItem("User_fullName");
    },
    searchRoles: _.debounce(async function(search) {
      const filter = {
          limit:this.limit,
          name: search,
        };
        const res= await this.$server.find("roles",filter);

      if (res.content.results) this.roles = res.content.results;
      else this.roles = [];
    }, 500),
    CreateNewRole() {
      localStorage.setItem("User_email", this.formData.email);
      localStorage.setItem("User_username", this.formData.username);
      localStorage.setItem("User_fullName", this.formData.fullName);
      this.$router.push("/roles/new");
    },
    Showfees() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    async save() {
      if (this.formData._id) {
        this.ConvertArrayToCountyCode(this.formData.countries);
        this.formData.email = this.formData.email.toLowerCase().trim();

        if (this.formData.type == "Seller") {
          const dataWahrhouse = await this.$server.get("users", {
            id: this.$route.params.id,
            warehouse: this.idWharhouse._id,
          });
          const dataWahrhouseSetting = await this.$server.get("users", {
            id: this.$route.params.id,
            warehouseSettings: this.idWharhouse._id,
          });
          if (dataWahrhouse.content.length > 0) {
            this.changeData(
              this.formData.fees,
              this.idWharhouse._id,
              this.fees
            );
          } else {
            this.fees.warehouse = await this.idWharhouse._id;
            this.formData.fees.push(this.fees);
          }
          if (dataWahrhouseSetting.content.length > 0) {
            this.changeData(
              this.formData.settings,
              this.idWharhouse._id,
              this.settings
            );
          } else {
            this.settings.warehouse = await this.idWharhouse._id;
            this.formData.settings.push(this.settings);
          }
        }
        if (this.formData.countries.length == 0) {
          alert("Countries required", "warning");
          return false;
        }
        const data = await this.$server.update("users", this.formData);

        if (data && data._id) alert(this.$t("user_updated"), "success");
        else alert(data, "warning");
      } else {
        if (this.formData.role == null && this.formData.type == "") {
          alert(this.$t("role_required"), "warning");
        } else {
          if (
            localStorage.getItem("Role_id") === null &&
            localStorage.getItem("User_email") === null
          ) {
            this.formData.type = this.formData.role.type;
          }
          if (this.checkData()) {
            if (this.formData.username.length < 3) {
              alert(this.$t("username_not_valid"), "warning");
              return false;
            }
            // this.formData.role = this.selectedRole;
            // this.formData.categories = [];
            // this.formData.categories.push(this.formData.category);

            this.formData.email = this.formData.email.toLowerCase().trim();
            const checkUser = await this.$server.find("users", {
              name: this.formData.email,
            });

            if (checkUser.content.results.length > 0) {
              alert(this.$t("email_exist"), "warning");
              return false;
            }
            this.ConvertArrayToCountyCode(this.formData.countries);
            delete this.formData.fees;
            delete this.formData.settings;
            const data = await this.$server.create("users", this.formData);
            if (data && data._id) {
              alert(this.$t("user_created"), "success");
              this.DeleteDtaStorage();
              this.selectContries = [];
              this.formData = {
                zones: [],
              };
              for (let i = 0; i < this.zones.length; i++) {
                this.zones[i].check = "";
              }
            } else alert(data, "warning");
          } else {
            alert(this.$t("all_required_field"), "warning");
          }
        }
      }
    },
    getRowDetail(zone_id) {
      let itemInRow = this.formData.zones.filter((item) => item === zone_id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.formData.zones
          .map(function (e) {
            return e;
          })
          .indexOf(zone_id);
        this.formData.zones.splice(pos, 1);
      } else {
        this.formData.zones.push(zone_id);
      }
    },
    checkData() {
      if (
        this.formData.countries.length > 0 &&
        this.formData.role &&
        this.formData.email &&
        this.formData.type &&
        this.formData.username &&
        this.formData.fullName
      )
        return true;
      else return false;
    },
    async getRoleByType() {
      const filter = {
        type: "Courier",
      };
      const res = await this.$server.get("roles", filter);
      this.livreur = res.content._id;
    },
    onChangeRole(data) {
      if (data && !this.formData._id) {
        this.formData.type = data.type;
        this.selectedRole = data._id;
      }
    },
    addZones(item) {
      if (item.check) {
        const zone = {
          _id: item._id,
        };
        this.tempZones.push(zone);
      } else {
        let pos = this.tempZones
          .map(function (e) {
            return e._id;
          })
          .indexOf(item._id);
        this.tempZones.splice(pos, 1);
      }
    },
    /******************************************************************************************/
    async getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        this.loading = true;
        const editData = await this.$server.get("users", {
          id: this.$route.params.id,
        });
        if (editData) this.loading = false;
        const dataWahrhouse = await this.$server.get("users", {
          id: this.$route.params.id,
          warehouse: this.idWharhouse._id,
        });
        const dataWahrhouseSetting = await this.$server.get("users", {
          id: this.$route.params.id,
          warehouseSettings: this.idWharhouse._id,
        });
        if (dataWahrhouse.content.length > 0) {
          this.fees = await this.getElementInArray(
            dataWahrhouse.content[0].fees,
            this.idWharhouse._id
          );
        } else {
          this.fees = {
            confirmation: -1,
            fulfillement_fees: -1,
            shipping: -1,
            refund: -1,
            change: -1,
            quality_control: -1,
          };
        }
        if (dataWahrhouseSetting.content.length > 0) {
          this.settings = await this.getElementInArray(
            dataWahrhouseSetting.content[0].settings,
            this.idWharhouse._id
          );
        } else {
          this.settings = {
            open_colis: false,
            confirmation: false,
            fulfillement: false,
            shipping: false,
          };
        }
        this.formData = editData.content;
        if (!this.formData.settings) this.formData.settings = [];
        // this.formData.role=this.formData.role._id;
        this.roleformdata = this.formData.role;
        //this.selectContries=this.formData.countries;
        this.ConvertArrayToCountyName(this.formData.countries);

        // this.formData.category = this.formData.categories[0];
        this.selectedRole = this.formData.role._id;
        // this.currentZone=this.formData.zones;
        // for (let j = 0; j <this.currentZone.length; j++) {
        //     for (let i = 0; i <this.allZones.length; i++) {
        //       if(this.zones[i]._id == this.currentZone[j]){
        //           this.zones[i].check=this.zones[i]._id;
        //         }
        //       }
        //     }
      }
    },
    ConvertArrayToCountyName(data) {
      data.forEach(
        function (part, index, theArray) {
          var count = part.length;
          if (count == 2)
            theArray[index] = this.$f.getCountryNameByCode(this.options, part);
        }.bind(this)
      );
    },
    ConvertArrayToCountyCode(data) {
      data.forEach(
        function (part, index, theArray) {
          var count = part.length;
          if (count > 2)
            theArray[index] = this.$f.getCountryCodeByName(this.options, part);
        }.bind(this)
      );
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content.results) this.categories = res.content.results;
      else this.categories = [];
    },

    async getRoles() {
      const res = await this.$server.search("roles", { limit: 1000 });
      if (res.content.results) this.roles = res.content.results;
      else this.roles = [];
    },
    async getZones() {
      const res = await this.$server.search("zones", {
        limit: 1000,
        country: await this.warhouseSelected,
      });
      if (res.content.results) {
        this.zones = res.content.results;

        for (let i = 0; i < this.zones.length; i++) {
          this.allZones.push(this.zones[i]._id);
        }
      } else {
        this.zones = this.allZones = [];
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: none;
}

.object {
  width: 300px;
  height: 300px;
  margin-left: 80px;
  opacity: 0;
  background: white;
  border-radius: 5%;
  border-color: #d3d3d3;
  border-width: thin;
  transform: scaleY(0.8) skewX(10deg);
  transform-origin: 50% 0%;
  transition-duration: 0.25s;
}

.button:hover+.object {
  opacity: 1;
  transform: scaleY(1) skewX(0deg);
}

.box-1 {
  background: #f2f4f7;
  border: 2px solid #e8ebed;
  border-radius: 0.375rem;
}
</style>
