var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-16 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center justify-between w-full border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_user")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_user")) + " ")])]), _vm.formData._id && _vm.formData.role.type == 'Courier' ? _c('div', {
    staticClass: "pt-3 pb-4 flex justify-end items-center w-full"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-fit py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.$vm2.open('GenerateModal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Generate Credentials")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded-lg p-6"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "fullName"
    }
  }, [_vm._v(_vm._s(_vm.$t("full_name")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.fullName,
      expression: "formData.fullName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "fullName",
      "disabled": _vm.formData._id,
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "fullName", $event.target.value);
      }
    }
  })])]), _vm.formData._id ? _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "phone"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.phone,
      expression: "formData.phone"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "phone",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "phone", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "username"
    }
  }, [_vm._v(_vm._s(_vm.$t("username")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.username,
      expression: "formData.username"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "username",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.username
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) return null;
        return function (event) {
          return event.preventDefault();
        }.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "username", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.email,
      expression: "formData.email"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "email", $event.target.value);
      }
    }
  })])]), _vm.formData._id ? _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "company"
    }
  }, [_vm._v(_vm._s(_vm.$t("store")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.company,
      expression: "formData.company"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.company
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "company", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "border h-10 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("role")) + " *")])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.roles
    },
    on: {
      "input": function input($event) {
        return _vm.onChangeRole(_vm.formData.role);
      },
      "search": function search($event) {
        return _vm.searchRoles($event, 'hh');
      }
    },
    model: {
      value: _vm.formData.role,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "role", $$v);
      },
      expression: "formData.role"
    }
  })], 1)]), _vm.livreur && _vm.selectedRole == _vm.livreur ? _c('div', {
    staticClass: "border hover:border-green-500 mt-4 px-4 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("select_zones")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('div', {}, [_c('div', {
    staticClass: "w-full mt-2"
  }, [_c('table', {
    staticClass: "table w-full text-center"
  }, [_c('thead', {
    staticClass: "bg-gray-100 text-black"
  }, [_c('tr', [_c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("zones")) + " ")]), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }), _c('th', {
    staticClass: "px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])])]), _c('tbody', _vm._l(_vm.zones, function (item, index) {
    return _c('tr', {
      key: index,
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-700 capitalize"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }), _c('td', {
      staticClass: "px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
    }, [_c('p', {
      staticClass: "text-gray-600 ml-3 text-semibold capitalize"
    }, [_c('label', {
      staticClass: "inline-flex text-sm items-center capitalize"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.check,
        expression: "item.check"
      }],
      staticClass: "form-checkbox rowCheckbox border-gray-500 h-4 w-4 text-green-500",
      attrs: {
        "type": "checkbox",
        "id": item._id
      },
      domProps: {
        "checked": Array.isArray(item.check) ? _vm._i(item.check, null) > -1 : item.check
      },
      on: {
        "change": [function ($event) {
          var $$a = item.check,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "check", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "check", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "check", $$c);
          }
        }, function ($event) {
          return _vm.addZones(item);
        }],
        "click": function click($event) {
          return _vm.getRowDetail(item._id);
        }
      }
    })])])])]);
  }), 0)])])])]) : _vm._e(), _vm.formData._id && _vm.formData.type == 'Seller' ? _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "rib"
    }
  }, [_vm._v(_vm._s(_vm.$t("rib")) + " *")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.rib,
      expression: "formData.rib"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.rib
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "rib", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.$f.getAccess(_vm.currentUser.role.permissions, 'users', 'create') ? _c('div', {
    staticClass: "border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Countries"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("countries")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "countryName",
      "options": _vm.warehouses,
      "multiple": ""
    },
    on: {
      "input": _vm.setSelectedCountries
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.country)
          }
        }), _vm._v(" " + _vm._s(option.countryName) + " ")];
      }
    }], null, false, 3617025755),
    model: {
      value: _vm.formData.countries,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "countries", $$v);
      },
      expression: "formData.countries"
    }
  })], 1)]) : _vm._e(), !_vm.formData._id ? _c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('span', {
    staticClass: "w-1/5 border-b dark:border-gray-600 md:w-1/4"
  }), _c('span', {
    staticClass: "text-xs text-green-500 font-bold cursor-pointer uppercase dark:text-green-400 hover:underline",
    on: {
      "click": _vm.CreateNewRole
    }
  }, [_vm._v(_vm._s(_vm.$t("create_new_role")))]), _c('span', {
    staticClass: "w-1/5 border-b dark:border-gray-600 md:w-1/4"
  })]) : _vm._e()]), _vm.formData._id && _vm.formData.type == 'Seller' ? _c('div', [_c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium font-medium text-base cursor-pointer text-green-500 w-full",
    on: {
      "click": _vm.Showfees
    }
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v(_vm._s(_vm.$t("fees_seller")))]), _c('span', {
    staticClass: "block"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "transition": "0.3s"
    },
    style: _vm.showMoreInfo ? 'transform: rotate(180deg)' : ''
  }, [_vm._v("keyboard_arrow_down")])])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _vm.showMoreInfo ? _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("confirmation_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.confirmation,
      expression: "fees.confirmation"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.confirmation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "confirmation", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "fulfillement_fees"
    }
  }, [_vm._v(_vm._s(_vm.$t("fulfillement_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.fulfillement_fees,
      expression: "fees.fulfillement_fees"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.fulfillement_fees
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "fulfillement_fees", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.shipping,
      expression: "fees.shipping"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.shipping
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "shipping", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("return_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.refund,
      expression: "fees.refund"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.refund
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "refund", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("cancelation_fees")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.cancelation,
      expression: "fees.cancelation"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.cancelation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "cancelation", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "w-full lg:w-1/5 px-4 mb-4"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "grid-password"
    }
  }, [_vm._v(_vm._s(_vm.$t("quality_control")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fees.quality_control,
      expression: "fees.quality_control"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.fees.quality_control
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.fees, "quality_control", $event.target.value);
      }
    }
  })])])])]) : _vm._e(), _c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium text-sm cursor-pointer text-green-500 w-full"
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v(_vm._s(_vm.$t("services")))])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _c('div', {
    staticClass: "flex flex-col lg:flex-row"
  }, [_c('div', {
    staticClass: "intro-y flex-1 box-1 py-16 cursor-pointer zoom-in",
    class: {
      active: _vm.settings.confirmation
    },
    attrs: {
      "id": "confirmation"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('confirmation');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Confirmation ")])]), _c('div', {
    staticClass: "intro-y flex-1 box-1 py-16 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in",
    class: {
      active: _vm.settings.fulfillement
    },
    attrs: {
      "id": "fulfillement"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('fulfillement');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Warehouse ")])]), _c('div', {
    staticClass: "intro-y flex-1 box-1 py-16 lg:ml-5 cursor-pointer zoom-in",
    class: {
      active: _vm.settings.shipping
    },
    attrs: {
      "id": "shipping"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('shipping');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Shipping ")])])])]) : _vm._e(), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "rounded mt-24 focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])], 1)]), _c('vue-modal-2', {
    attrs: {
      "name": "GenerateModal",
      "headerOptions": {
        title: _vm.$t('Generate Credentials')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.CloseModal('GenerateModal');
      }
    }
  }, [_c('div', {
    staticClass: "relative flex flex-col w-full h-auto"
  }, [_c('loading', {
    attrs: {
      "active": _vm.generateLoading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.generateLoading = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded px-8 flex flex-col"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm.formData && _vm.formData.fullName && _vm.formData.fullName.toString().toLowerCase() !== 'camex' ? _c('div', [_c('label', {
    staticClass: "block text-grey-darker text-sm font-bold mb-2",
    attrs: {
      "for": "apiKey"
    }
  }, [_vm._v(_vm._s(_vm.$t("Api Key")))]), _c('div', {
    staticClass: "focus:outline-none h-12 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formData.api.key ? _vm.formData.api.key : 'api key ....') + " ")])])]) : _vm._e(), _c('label', {
    staticClass: "block text-grey-darker text-sm font-bold mb-2",
    attrs: {
      "for": "apiSecret"
    }
  }, [_vm._v(_vm._s(_vm.$t("Api Secret")))]), _c('div', {
    staticClass: "focus:outline-none shadow h-12 appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formData.api.secret ? _vm.formData.api.secret : 'api secret ....') + " ")])]), !_vm.formData.api.secret && !_vm.formData.api.key ? _c('div', {
    staticClass: "flex space-x-2"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-full my-4 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.GenerateCredentials(_vm.formData._id);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Generate")))])]) : _c('div', {
    staticClass: "flex space-x-2"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-full my-4 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.CopyCredentials(_vm.formData.api.key, _vm.formData.api.secret, _vm.formData.fullName);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Copy Credentials")))])])])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }